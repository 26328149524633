import { Container } from '@mui/material';
import { EventPollsGrid } from '../../containers/eventPolls/EventPollsListGrid';

// const JoinSection = () => (
//     <Card>
//         <CardContent>
//             <Typography variant="h5" component="div" textAlign='center' margin={1}>
//                 {texts[lang].joinUs}
//             </Typography>
//             <Typography variant="body2" color="text.secondary" textAlign='center'>
//                 {texts[lang].takeParticipationOnPollAndImproveQualityOfYourLife}
//                 <br />
//                 <Button color="success" component={Link} to={TG_PLATFORM_CH_URL} style={{ border: '2px solid green', borderRadius: '5px' }}>
//                     {texts[lang].horizonOfEvents}
//                 </Button>
//             </Typography>
//         </CardContent>
//     </Card>
// );

export const Home = () => (
    <Container style={{ padding: '1rem' }}>
        <EventPollsGrid />
        {/* <RandomEventPoll /> */}
    </Container>
);

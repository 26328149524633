import { Box, Container, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import * as texts from '../configs/texts'
import styles from '../styles'
import { requestHelper } from '../helpers/requestHelper';
import { EApiRoutes, ERoutes, lang } from '../configs/constants';
import { IAuthorizeProps, INotificationProps, IUserInfo } from '../types';
import { a11yProps, TabPanel } from '../components/TabPanel';
import { deleteUserData, onUserAuth, prepareUrlWithId } from '../helpers';
import { useNavigate } from 'react-router';

const Login = (props: { onAuthorize: React.Dispatch<React.SetStateAction<string>> }) => {
    const [userData, userDataUpdate] = useState({
        username: '',
        password: ''
    })
    const navigate = useNavigate()
    const onSubmit = async () => {
        console.log('userData > ', userData)
        const response: IUserInfo = await requestHelper.request({
            method: 'post',
            url: EApiRoutes.userAuth,
            data: userData
        });

        if (response?.authToken) {
            navigate(prepareUrlWithId(ERoutes.user, response.id));
            onUserAuth(response, props.onAuthorize);
            
            // const { authToken, ...userInfo } = response
            // localStorage.setItem(storageKeys.authToken, authToken)
            // localStorage.setItem(storageKeys.userInfo, JSON.stringify(userInfo))
            // props.onAuthorize(response.authToken)
        } else {
            deleteUserData()
        }
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            autoComplete="off"
        >
            <TextField
                required
                id="outlined-required"
                label={texts[lang].username}
                onChange={e => userDataUpdate({ ...userData, username: e.target.value })}
            />
            <TextField
                required
                type='password'
                id="outlined-required"
                label={texts[lang].password}
                onChange={e => userDataUpdate({ ...userData, password: e.target.value })}
            />
            <Button
                variant="contained"
                onClick={onSubmit}
                disabled={!(userData.username && userData.password)}
            >
                {texts[lang].send}
            </Button>
            {/* <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'center' }}>
                <img src='https://www.google.com/images/branding/googleg/1x/googleg_standard_color_128dp.png' alt='google_auth' style={styles.icon32}></img>
            </div> */}
        </Box>
    )
}

// const Register = (props: {
//     onRegister: Function
// }) => {
//     const [userData, userDataUpdate] = useState({
//         username: '',
//         email: '',
//         password: '',
//         passwordConfirm: '',
//         firstName: '',
//         lastName: '',
//         dataForVerify: '',
//     })
//     const [submitBtnPressed, submitBtnPressedUpdate] = useState(false)
//     const onSubmit = () => {
//         // console.log('userData > ', userData)
//         submitBtnPressedUpdate(true);

//         requestHelper.request({
//             method: 'post',
//             url: EApiRoutes.user,
//             data: userData
//         }).then((res: IBaseServerResponse) => {
//             console.log('res > ', res)
//             if (res.statusCode === 'ok') {
//                 props.onRegister()
//             }
//         })
//     }

//     return (
//         <Box
//             component="form"
//             sx={{
//                 '& .MuiTextField-root': { m: 1, width: '25ch' },
//             }}
//             autoComplete="off"
//         >
//             <TextField
//                 required
//                 id="outlined-required"
//                 label={texts[lang].uniqueUsername}
//                 onChange={e => userDataUpdate({ ...userData, username: e.target.value })}
//             />
//             <TextField
//                 required
//                 id="outlined-required"
//                 label={texts[lang].email}
//                 onChange={e => userDataUpdate({ ...userData, email: e.target.value })}
//             />
//             <TextField
//                 required
//                 type='password'
//                 id="outlined-required"
//                 label={texts[lang].password}
//                 onChange={e => userDataUpdate({ ...userData, password: e.target.value })}
//             />
//             <TextField
//                 required
//                 type='password'
//                 id="outlined-required"
//                 label={texts[lang].confirmPassword}
//                 onChange={e => userDataUpdate({ ...userData, passwordConfirm: e.target.value })}
//             />
//             <TextField
//                 required
//                 id="outlined-required"
//                 label={texts[lang].firstName}
//                 onChange={e => userDataUpdate({ ...userData, firstName: e.target.value })}
//             />
//             <TextField
//                 required
//                 id="outlined-required"
//                 label={texts[lang].lastName}
//                 onChange={e => userDataUpdate({ ...userData, lastName: e.target.value })}
//             />
//             <TextField
//                 required
//                 id="outlined-required"
//                 label={`${texts[lang].additionalData},${texts[lang].socNetworkPageForExample}`}
//                 onChange={e => userDataUpdate({ ...userData, dataForVerify: e.target.value })}
//             />
//             <Button
//                 variant="contained"
//                 onClick={onSubmit}
//                 disabled={
//                     !submitBtnPressed &&
//                     !(
//                         userData.firstName &&
//                         userData.lastName &&
//                         userData.username &&
//                         userData.dataForVerify &&
//                         userData.password &&
//                         userData.password === userData.passwordConfirm &&
//                         userData.email.match(EMAIL_REGEX)
//                     )}>
//                 {texts[lang].send}
//             </Button>
//             {/* <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'center' }}>
//                 <img src='https://www.google.com/images/branding/googleg/1x/googleg_standard_color_128dp.png' style={styles.icon32}></img>
//             </div> */}
//         </Box>
//     )
// }

const AuthorizeContainer = (props: {
    onAuthorize: React.Dispatch<React.SetStateAction<string>>
    notificationUpdate: React.Dispatch<React.SetStateAction<INotificationProps>>
}) => {
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };
    // const onRegister = () => {
    //     props.notificationUpdate({
    //         notificationType: ENotificationType.success,
    //         message: texts[lang].registeredWithRequiredEmailConfirmation
    //     })
    //     setTabValue(0)
    // }

    return (
        <Container className='auth-container' style={styles.authContainer as any}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="login-register">
                    <Tab label={texts[lang].login} {...a11yProps(0)} />
                    <Tab label={texts[lang].register} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <Login onAuthorize={props.onAuthorize} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <div>{texts[lang].registerUnaccessible}</div>
                {/* <Register onRegister={onRegister} /> */}
            </TabPanel>
        </Container>
    )
}

export const Authorize = (props: IAuthorizeProps & any) => {
    console.log('props > ', props)
    return props.authorized
        ? <Container style={{ marginTop: '30px' }}>{props.children}</Container>
        : <AuthorizeContainer onAuthorize={props.onAuthorize} notificationUpdate={props.notificationUpdate} />
}

import { Container, Typography } from '@mui/material'

export const Loader = () => {
    return (
        <Container>
            <Typography component='div' textAlign='center'>
                <div className='lds-dual-ring'></div>
            </Typography>
        </Container>
    )
}

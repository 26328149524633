import React, { useState } from 'react';
import { Box, Button, FormControl, FormControlLabel, FormGroup, Input, InputLabel, Modal, Radio, RadioGroup, TableBody, TableCell, TableRow } from '@mui/material';
import texts from '../../configs/texts';
import { ETransferTypes, lang } from '../../configs/constants';
import { Save } from '@mui/icons-material';
import securityIcon from '../../images/security_icon.png'
import transactionIcon from '../../images/transaction_icon.png'
import { IUserTransaction, IUserVote } from '../../types';

interface IChangePasswordBaseData { old: string, password: string }
interface ITransferRequestBaseData {
    amount: number,
    transferType: ETransferTypes.depositRequest | ETransferTypes.withdrawalRequest
    externalCurrency: string // TODO take allowed currency from settings
}

export const ChangePasswordModal = (props: {
    onSaveSubmit: (data: IChangePasswordBaseData) => Promise<void>
}) => {
    const [newItem, newItemUpdate] = useState<IChangePasswordBaseData & { confirmNew: string }>({
        old: '',
        password: '',
        confirmNew: ''
    });

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onSave = () => {
        props.onSaveSubmit(newItem).then(() => {
            handleClose();
            newItemUpdate({ old: '', password: '', confirmNew: '' })
        })
    }

    return (
        <div>
            <Button onClick={handleOpen}>
                <img style={{ width: '32px' }} src={securityIcon} alt='confirm' />
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                style={{ top: '20%' }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}
                    style={{
                        padding: '25px',
                        border: '1px solid green',
                        borderRadius: '5%'
                    }}
                >
                    <FormGroup className='create-form-group'>
                        <FormControl>
                            <InputLabel id={`${texts[lang].oldPassword}-label`}>{texts[lang].oldPassword}</InputLabel>
                            <Input
                                type='password'
                                value={newItem.old}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    old: e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].newPassword}-label`}>{texts[lang].newPassword}</InputLabel>
                            <Input
                                type='password'
                                value={newItem.password}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    password: e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].confirmPassword}-label`}>{texts[lang].confirmPassword}</InputLabel>
                            <Input
                                type='password'
                                value={newItem.confirmNew}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    confirmNew: e.target.value
                                })}
                            />
                        </FormControl>
                        {
                            (newItem.old?.length && newItem.password?.length && newItem.password === newItem.confirmNew) ? (
                                <Button onClick={() => onSave()}><Save color='success' /></Button>
                            ) : ''
                        }
                    </FormGroup>
                </Box>
            </Modal>
        </div>
    );
}

export const UserVoteDetailsModal = (props: {
    item: IUserVote
}) => {
    const { item } = props
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button onClick={handleOpen}>
                {item.eventPoll.name}
            </Button>
            <Modal
                open={open}
                style={{ top: '10%' }}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}
                    style={{
                        padding: '25px',
                        border: '1px solid green',
                        borderRadius: '5%'
                    }}
                >
                    <TableBody>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].name}</TableCell>
                            <TableCell align='right'>{item.eventPoll.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].createdAt}</TableCell>
                            <TableCell align='right'>{item.createdAt?.toString()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].selectedOption}</TableCell>
                            <TableCell align='right'>{item.eventPoll.options[item.selectedOption]}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].result}</TableCell>
                            <TableCell align='right'>{item.eventPoll.options[item.eventPoll.confirmedResultAsNumber || -1] || ''}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].totalBankAmount}</TableCell>
                            <TableCell align='right'>{item.eventPoll.totalBankAmount}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].amountToVote}</TableCell>
                            <TableCell align='right'>{item.eventPoll.amountToVote}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].comissionPercent}</TableCell>
                            <TableCell align='right'>{item.eventPoll.comissionPercent}</TableCell>
                        </TableRow>
                        {
                            item.eventPoll.orgPercent ?
                                <TableRow>
                                    <TableCell align='right'>{texts[lang].orgPercent}</TableCell>
                                    <TableCell align='right'>{item.eventPoll.orgPercent}</TableCell>
                                </TableRow> : ''
                        }
                        <TableRow>
                            <TableCell align='right'>{texts[lang].statusOfEvent}</TableCell>
                            <TableCell align='right'>{item.eventPoll.status}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].amountForWinner}</TableCell>
                            <TableCell align='right'>{item.eventPoll.amountForWinner}</TableCell>
                        </TableRow>
                    </TableBody>
                </Box>
            </Modal>
        </div >
    );
}

export const UserTransactionsDetailsModal = (props: {
    item: IUserTransaction
}) => {
    const { item } = props
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <div>
            <Button onClick={handleOpen}>
                {item.id}
            </Button>
            <Modal
                open={open}
                style={{ top: '10%' }}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}
                    style={{
                        padding: '25px',
                        border: '1px solid green',
                        borderRadius: '5%'
                    }}
                >
                    <TableBody>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].id}</TableCell>
                            <TableCell align='right'>{item.id}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].createdAt}</TableCell>
                            <TableCell align='right'>{item.createdAt?.toString()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].amount}</TableCell>
                            <TableCell align='right'>{
                                item.balanceAfter > item.balanceBefore
                                    ? ''
                                    : (item.balanceBefore > item.balanceAfter) ? '-' : ''
                            }{item.amount}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].balanceBefore}</TableCell>
                            <TableCell align='right'>{item.balanceBefore}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].balanceAfter}</TableCell>
                            <TableCell align='right'>{item.balanceAfter}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].updatedAt}</TableCell>
                            <TableCell align='right'>{item.updatedAt?.toString()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align='right'>{texts[lang].status}</TableCell>
                            <TableCell align='right'>{item.status}</TableCell>
                        </TableRow>
                    </TableBody>
                </Box>
            </Modal>
        </div >
    );
}

export const TransferRequestModal = (props: {
    onSaveSubmit: (data: ITransferRequestBaseData) => Promise<void>
}) => {
    const [newItem, newItemUpdate] = useState<ITransferRequestBaseData>({
        transferType: ETransferTypes.depositRequest,
        amount: 0.99,
        externalCurrency: 'BTC', // ECurrencies.BTC 
    });

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onSave = () => {
        props.onSaveSubmit(newItem).then(() => {
            handleClose();
            newItemUpdate({
                transferType: ETransferTypes.withdrawalRequest,
                amount: 0.99,
                externalCurrency: 'BTC', // ECurrencies.BTC 
            })
        })
    }

    return (
        <div>
            <Button onClick={handleOpen}>
                <img style={{ width: '32px' }} src={transactionIcon} alt='confirm' />
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                style={{ top: '20%' }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}
                    style={{
                        padding: '25px',
                        border: '1px solid green',
                        borderRadius: '5%'
                    }}
                >
                    <FormGroup className='create-form-group'>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={newItem.transferType}
                                name="radio-buttons-group"
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    transferType: e.target.value as any
                                })}
                            >
                                <FormControlLabel value={ETransferTypes.depositRequest} control={<Radio />} label={texts[lang].deposit} />
                                <FormControlLabel value={ETransferTypes.withdrawalRequest} control={<Radio />} label={texts[lang].withdrawal} />
                            </RadioGroup>
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].amount}-label`}>{texts[lang].amount}</InputLabel>
                            <Input
                                type='number'
                                value={newItem.amount}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    amount: +e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            {/* <InputLabel id={`${texts[lang].externalCurrency}-label`}>{texts[lang].externalCurrency}</InputLabel> */}
                            <RadioGroup
                                aria-labelledby="externalCurrency-radio-buttons-group-label"
                                value={newItem.externalCurrency}
                                name="externalCurrency-radio-buttons-group"
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    externalCurrency: e.target.value as any
                                })}
                            >
                                <FormControlLabel value='BTC' control={<Radio />} label='BTC' />
                                <FormControlLabel value='ETH' control={<Radio />} label='ETH' />
                            </RadioGroup>
                        </FormControl>
                        {
                            (newItem.amount >= 1) ? (
                                <Button onClick={() => onSave()}><Save color='success' /></Button>
                            ) : ''
                        }
                    </FormGroup>
                </Box>
            </Modal>
        </div>
    );
}

import React from 'react';
import { Box, Grid,Container } from '@mui/material';
import texts from '../configs/texts';
import { lang } from '../configs/constants';

export const Footer: React.FC = () => {
    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: 'primary.main',
                color: 'white',
                paddingTop: 2,
                paddingBottom: 2,
                marginTop: 'auto', // Позволяет футеру всегда быть внизу страницы
            }}
            className='footer-bar'
        >
            <Container maxWidth="lg">
                <Grid container justifyContent='center'>
                    © {new Date().getFullYear()} {texts[lang].clubNameValue}
                </Grid>
            </Container>
        </Box>
    );
};

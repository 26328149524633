import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthCtx } from '../ctx/AuthContext';
import { ERoutes } from '../configs/constants';
import { isAdmin, prepareUrlWithId } from '../helpers';

interface PrivateRouteProps {
    children: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const { user } = useAuthCtx();

    if (!user) {
        return <Navigate to={prepareUrlWithId(ERoutes.user, '0')} />;
    }

    if (!isAdmin()) {
        return <Navigate to="/" />;
    }

    return children;
};

export default PrivateRoute;

import { storageKeys } from '../configs/constants'
import { IUserInfo, IUserInfoFromAdmin } from '../types'

export const onUserAuth = (user: IUserInfo, onAuthHook: Function) => {
    const { authToken, ...userInfo } = user
    localStorage.setItem(storageKeys.authToken, authToken as string)
    localStorage.setItem(storageKeys.userInfo, JSON.stringify(userInfo))
    onAuthHook(authToken)
}

export const isAdmin = () => getUserInfo()?.role === 'admin'

export const getAuthToken = (): string => localStorage.getItem(storageKeys.authToken) as string

export const deleteUserData = (): void => {
    localStorage.removeItem(storageKeys.authToken);
    localStorage.removeItem(storageKeys.userInfo)
}

export const getUserInfo = (): IUserInfo => JSON.parse(localStorage.getItem(storageKeys.userInfo) as string) as IUserInfo

export const getBaseUser: () => Partial<IUserInfoFromAdmin> = () => ({
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    dataForVerify: '',
    password: '',
    confirmPassword: '',
})

import { EApiRoutes } from '../../configs/constants'
import { useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { Button, FormControl, Input, InputLabel, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { Loader } from '../../components/Loader'
import { AxiosRequestConfig } from 'axios'
import { IRewards } from '../../types'
import { Save } from '@mui/icons-material'
import { prepareUrlWithId } from '../../helpers'

const RewardsRow = (props: {
    item: IRewards
    onSaveSubmit: (entityId: number, data: Partial<IRewards>) => void
    // navigate: NavigateFunction
}) => {
    const { item } = props

    const [updatedItem, updatedItemUpdate] = useState<Pick<IRewards, 'id' | 'isActive' | 'availableForLevel' | 'description'>>({
        id: item.id,
        isActive: item.isActive,
        availableForLevel: item.availableForLevel,
        description: item.description,
    });

    return (
        <TableRow>
            <TableCell align='right'>{item.id}</TableCell>
            <TableCell align='right'>{item.name}</TableCell>
            <TableCell align='right'>
                <FormControl>
                    <InputLabel id={`${item.id}-description-label`}>Описание</InputLabel>
                    <Input
                        placeholder='Описание'
                        value={updatedItem.description}
                        onChange={(e): any => updatedItemUpdate({
                            ...updatedItem,
                            description: e.target.value
                        })}
                    />
                </FormControl>
            </TableCell>
            <TableCell align='right'>
                <FormControl
                    style={{ width: '150px', marginTop: '30px' }}>
                    <InputLabel id={`reward-isactive-label`} style={{ marginTop: '-30px' }}>Активно</InputLabel>
                    <Switch
                        checked={updatedItem.isActive}
                        onChange={(e): any => updatedItemUpdate({
                            ...updatedItem,
                            isActive: e.target.checked
                        })}
                    />
                </FormControl>
            </TableCell>
            <TableCell align='right'>
                <FormControl>
                    <InputLabel id={`available-for-level-label`}>Виток</InputLabel>
                    <Input
                        placeholder='Виток'
                        type='number'
                        value={updatedItem.availableForLevel}
                        onChange={(e): any => updatedItemUpdate({
                            ...updatedItem,
                            availableForLevel: +e.target.value
                        })}
                    />
                </FormControl>
            </TableCell>
            <TableCell align='right'>{item.createdAt.toString()}</TableCell>
            <TableCell align='right'>{item.updatedAt.toString()}</TableCell>
            <TableCell align='right'>
                {
                    (
                        updatedItem.isActive !== item.isActive ||
                        updatedItem.availableForLevel !== item.availableForLevel
                    ) ? (
                        <Button
                            onClick={() => props.onSaveSubmit(item.id, updatedItem)}
                        ><Save color='success' /></Button>
                    ) : ''
                }
            </TableCell>
        </TableRow>
    )
}

export const Rewards = () => {
    const [data, dataUpdate] = useState<IRewards[]>(null as any)

    const getData = () => {
        const preparedParams: AxiosRequestConfig = {
            method: 'get',
            url: EApiRoutes.adminRewards,
        }
        requestHelper
            .request(preparedParams)
            .then((items) => {
                dataUpdate(items)
            })
    }

    if (!data) getData();

    const onSaveSubmit = (entityId: number, updateData: Partial<IRewards>) => {
        if (entityId) {
            const preparedParams: AxiosRequestConfig = {
                method: 'patch',
                url: prepareUrlWithId(EApiRoutes.adminRewardsById, entityId),
                data: updateData
            }
            requestHelper
                .request(preparedParams)
                .then(getData)
        } else {

        }
    }

    return data ? (
        <div>
            <Typography variant='h5'>Вознаграждения</Typography>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align='right'>id</TableCell>
                        <TableCell align='right'>Название</TableCell>
                        <TableCell align='right'>Описание</TableCell>
                        <TableCell align='right'>Активно</TableCell>
                        <TableCell align='right'>Виток</TableCell>
                        <TableCell align='right'>Дата создания</TableCell>
                        <TableCell align='right'>Дата обновления</TableCell>
                        <TableCell align='right'>Действия</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(reward => {
                        return <RewardsRow item={reward} onSaveSubmit={onSaveSubmit} />
                    })}
                </TableBody>
            </Table>

            {/* <FormGroup className='form-group'>
                <FormControl>
                    <InputLabel id={`externalCurrency-label`}>Внешняя валюта</InputLabel>
                    <Input
                        placeholder='Основная валюта'
                        value={settings.baseExternalCurrency}
                        onChange={(e): any => settingsUpdate({
                            ...settings,
                            baseExternalCurrency: e.target.value
                        })}
                    />
                </FormControl>
            </FormGroup> */}

            {/* <Button
                onClick={dataUpdate}
            ><Save color='success' /></Button> */}
        </div >
    ) : <Loader />
    // return <Loader /> */
}

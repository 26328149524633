import { EEventPollStatuses } from '../configs/constants';
import { IEventPoll } from '../types';

export const getBaseEventPoll: () => Partial<IEventPoll> = () => ({
    name: '',
    status: EEventPollStatuses.pending,
    voteAvailableUntilDate: new Date(),
    wayOfResultConfirmation: '',
    datetimeOfConfirmation: new Date(),
    initialBankAmount: 0,
    amountToVote: 0,
    comissionPercent: 1,
    orgPercent: 0,
    options: [],
    minParticipantsCount: 0,
    countryCodes: [],
})
import { EApiRoutes, ERoutes, EUSerRoles, EUserStatuses } from '../../configs/constants'
import { useEffect, useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { Button, Input, Table, Typography } from '@mui/material'
import { Loader } from '../../components/Loader'
import { Edit, Save } from '@mui/icons-material'
import { Option, Select } from '@mui/joy'
import { AxiosRequestConfig } from 'axios'
import { prepareUrlWithId, getBaseUser } from '../../helpers'
import { IUserInfo, IUserInfoFromAdmin } from '../../types'
import { NavigateFunction, useNavigate } from 'react-router'

const UsersRow = (props: {
    item: IUserInfo & IUserInfoFromAdmin
    onUpdateSubmit: Function
    navigate: NavigateFunction
}) => {
    const { item, onUpdateSubmit } = props

    const [updatedItem, updatedItemUpdate] = useState<Pick<IUserInfoFromAdmin, 'id' | 'status' | 'role'>>({
        id: item.id,
        status: item.status,
        role: item.role || '' as any,
    });

    // console.log('upd item > ', updatedItem);
    // console.log('item.status > ', item.id, ' > ', item.status?.toString(), ' > ', updatedItem.status?.toString());

    return (
        <tr>
            <td>{item.id}</td>
            <td>{item.username}</td>
            <td>{item.email}</td>
            {/* <td>{item.createdAt}</td> */}
            <td>
                <Select
                    placeholder='Status'
                    value={updatedItem.status}
                    onChange={(_, newValue): any => updatedItemUpdate({
                        ...updatedItem,
                        status: newValue as EUserStatuses
                    })}>
                    {
                        Object.values(EUserStatuses).map((status: EUserStatuses) => (
                            <Option value={status}>{status}</Option>
                        ))
                    }
                </Select>
            </td>
            <td>{item.firstName}</td>
            <td>{item.lastName}</td>
            {/* <td>{item.phone}</td> */}
            <td>{item.dataForVerify}</td>
            <td>
                <Select
                    placeholder='Role'
                    defaultValue={updatedItem.role}
                    onChange={(_, newValue): any => updatedItemUpdate({
                        ...updatedItem,
                        role: newValue as EUSerRoles
                    })}>
                    {
                        Object.values(EUSerRoles).map((val: EUSerRoles) => (
                            <Option value={val}>{val}</Option>
                        ))
                    }
                </Select>
            </td>
            <td>{item.wallet?.balance}</td>
            <td>
                {
                    !(
                        item.status?.toString() === updatedItem.status?.toString() &&
                        item.role?.toString() === updatedItem.role?.toString()
                    ) && (
                        <Button
                            onClick={() => onUpdateSubmit(updatedItem)}
                        ><Save color='success' /></Button>
                    )
                }
                <Button
                    onClick={() => props.navigate(prepareUrlWithId(ERoutes.adminManageUser, item.id))}
                ><Edit color='info' /></Button>
            </td>
        </tr>
    )
}

export const ManageUsers = () => {
    const [itemsList, itemsListUpdate] = useState<(IUserInfo & IUserInfoFromAdmin)[]>(null as any)
    const [newItem, newItemUpdate] = useState<Partial<IUserInfoFromAdmin>>({ ...getBaseUser() })
    const [filter, filterUpdate] = useState<Pick<IUserInfo, 'username'>>({} as any)

    const getItemsList = () => {
        const preparedParams: AxiosRequestConfig = {
            method: 'get',
            url: EApiRoutes.user,
            params: { filter: JSON.stringify(filter) }
        }
        requestHelper
            .request(preparedParams)
            .then((items) => {
                itemsListUpdate(items)
            })
    }
    useEffect(getItemsList, [filter]);

    const navigate = useNavigate()
    const onSaveSubmit = (item: Partial<IUserInfoFromAdmin>) => {
        if (item.confirmPassword && item.confirmPassword !== item.password) {
            throw new Error(`Passwords confirmation doesn't match`)
        }

        const preparedParams: AxiosRequestConfig = item.id
            ? {
                method: 'patch',
                url: prepareUrlWithId(EApiRoutes.userById, item.id),
                data: item
            } : {
                method: 'post',
                url: EApiRoutes.user,
                data: item,
            }

        requestHelper.request(preparedParams).then((createdOrUpdatedItem: IUserInfoFromAdmin) => {
            getItemsList()

            if (!item.id) {
                newItemUpdate({ ...getBaseUser() })
            }
        })
    }

    if (!itemsList) getItemsList()

    return itemsList ? (
        <div>
            <Typography variant='h6'>Пользователи</Typography>
            <tr>
                <td>
                    <Input
                        placeholder='Username'
                        value={newItem.username}
                        onChange={(e): any => newItemUpdate({
                            ...newItem,
                            username: e.target.value
                        })}
                    />
                </td>
                <td>
                    <Input
                        placeholder='Email'
                        value={newItem.email}
                        onChange={(e): any => newItemUpdate({
                            ...newItem,
                            email: e.target.value
                        })}
                    />
                </td>
                <td>
                    <Input
                        placeholder='First name'
                        value={newItem.firstName}
                        onChange={(e): any => newItemUpdate({
                            ...newItem,
                            firstName: e.target.value
                        })}
                    />
                </td>
                <td>
                    <Input
                        placeholder='Last name'
                        value={newItem.lastName}
                        onChange={(e): any => newItemUpdate({
                            ...newItem,
                            lastName: e.target.value
                        })}
                    />
                </td>
                <td>
                    <Input
                        placeholder='Phone'
                        value={newItem.phone}
                        onChange={(e): any => newItemUpdate({
                            ...newItem,
                            phone: e.target.value
                        })}
                    />
                </td>
                <td>
                    <Input
                        placeholder='Data for verify'
                        value={newItem.dataForVerify}
                        onChange={(e): any => newItemUpdate({
                            ...newItem,
                            dataForVerify: e.target.value
                        })}
                    />
                </td>
                <td>
                    <Input
                        placeholder='Password'
                        value={newItem.password}
                        type='password'
                        onChange={(e): any => newItemUpdate({
                            ...newItem,
                            password: e.target.value
                        })}
                    />
                </td>
                <td>
                    <Input
                        placeholder='Confirm password'
                        value={newItem.confirmPassword}
                        type='password'
                        onChange={(e): any => newItemUpdate({
                            ...newItem,
                            confirmPassword: e.target.value
                        })}
                    />
                </td>
                <td>
                    {
                        (newItem.username) && (
                            <Button onClick={() => onSaveSubmit(newItem)}><Save color='success' /></Button>
                        )
                    }
                </td>
            </tr>
            <br /><br />
            <Input
                placeholder='Filter by username...'
                value={filter.username}
                onChange={(e): any => filterUpdate(e.target.value ? {
                    username: e.target.value
                } : {} as any)}
            />
            <Table aria-label="basic table">
                <thead>
                    <tr>
                        <td>ID</td>
                        <td>Username</td>
                        <td>Email</td>
                        {/* <td>Date of creation</td> */}
                        <td>Status</td>
                        <td>First Name</td>
                        <td>Last Name</td>
                        {/* <td>Phone</td> */}
                        <td>Data for verify</td>
                        <td>Role</td>
                        <td>Balance</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        itemsList.map((item, i) => (
                            <UsersRow
                                key={i}
                                item={item}
                                navigate={navigate}
                                onUpdateSubmit={onSaveSubmit}
                            />
                        ))
                    }
                </tbody>
            </Table>
        </div >
    ) : <Loader />
    // return <Loader />
}


import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, List, ListItem, ListItemText } from '@mui/material';
import texts from '../../configs/texts';
import { EApiRoutes, lang } from '../../configs/constants';
import { requestHelper } from '../../helpers/requestHelper';

// Типы данных для FAQ
interface FaqItem {
    date: string;
    title: string;
    list: {
        question: string;
        answer: string;
    }[];
}

function FaqPage() {
    const [faqData, setFaqData] = useState<FaqItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchFaq = async () => {
            try {
                setFaqData(await requestHelper.request({
                    method: 'get',
                    url: EApiRoutes.dictFaq
                }));
            } catch (error) {
                console.error('Error fetching FAQ data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchFaq();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Box>
            <Typography variant='h4' gutterBottom>
                {texts[lang].faq}
            </Typography>
            {faqData.map((faq, index) => (
                <Box key={index} mb={4}>
                    <Typography variant='h6'>{faq.title}</Typography>
                    <Typography variant='caption' display='block' gutterBottom>
                        {faq.date}
                    </Typography>
                    <List>
                        {faq.list.map((item, idx) => (
                            <ListItem key={idx}>
                                <ListItemText
                                    primary={item.question}
                                    secondary={<span dangerouslySetInnerHTML={{ __html: item.answer }}></span>}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            ))}
        </Box>
    );
}

export default FaqPage;

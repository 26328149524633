module.exports = {
    link: {
        textDecoration: 'none',
        padding: '3px',
        fontSize: '1.2em'
    },
    appBar: {
        background: 'transparent'
    },
    appBarContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    appBarLinksContainer: {
        width: '25%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    authContainer: {
        marginTop: '25px',
        padding: '10px',
        textAlign: 'center',
        width: '300px',
    },
    userBar: {
        background: 'transparent',
        border: '1px grey solid',
        color: 'black',
    },
    userBarToolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    icon32: {
        display: 'block',
        width: '32px',
        height: '32px',
    },
    formBase: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    inputBase: {
        width: '250px',
    }
}

import { useEffect, useRef, useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { DEFAULT_GRID_ITEMS_COUNT, EApiRoutes, ENotificationType, lang, TG_PLATFORM_CH_URL } from '../../configs/constants'
import { Loader } from '../../components/Loader'
import { getUserInfo, prepareUrlWithId } from '../../helpers'
import { IEventPoll } from '../../types'
import {
    Box,
    Card,
    CardContent, Chip, FormControl, FormControlLabel,
    Grid,
    Radio, RadioGroup, Typography
} from '@mui/material'
import Button from '@mui/material/Button';
import texts from '../../configs/texts'
import { Link } from 'react-router-dom'
import "react-multi-carousel/lib/styles.css";
import { hookHelper } from '../../helpers/hookHelper'
import { IUserInfo } from '../../types'

// const responsive = {
//     superLargeDesktop: {
//         // the naming can be any, depends on you.
//         breakpoint: { max: 4000, min: 3000 },
//         items: 5
//     },
//     desktop: {
//         breakpoint: { max: 3000, min: 1024 },
//         items: 3
//     },
//     tablet: {
//         breakpoint: { max: 1024, min: 464 },
//         items: 2
//     },
//     mobile: {
//         breakpoint: { max: 464, min: 0 },
//         items: 1
//     }
// };

// const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
//     const { carouselState: { currentSlide } } = rest;
//     return (
//         <div
//             className="carousel-button-group"
//             style={{
//                 position: 'absolute',
//                 width: '100%',
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 bottom: '-1%',
//                 background: 'yellow',
//             }}
//         >
//             <Button
//                 className={currentSlide === 0 ? 'disable' : ''}
//                 onClick={() => previous()}
//                 style={{ fontSize: '24px' }}
//             >
//                 {'<'}
//             </Button>
//             {/* <Button onClick={() => next()}>B</Button> */}
//             <Button
//                 onClick={() => goToSlide(currentSlide + 1)}
//                 style={{ fontSize: '24px' }}
//             >{'>'}</Button>
//         </div>
//     );
// };

const EventPollsGridItem = (props: {
    item: IEventPoll,
    confirmEventPoll: (eventPollId: number, selectedOption: number) => void
}) => {
    const alreadyConfirmedData: {
        selectedOption: number
        user: Omit<IUserInfo, 'authToken'>
    } = props.item.participants?.find((pInfo: any) => pInfo.user.id === getUserInfo()?.id) as any;
    const [selectedOption, selectedOptionUpdate] = useState<number | undefined>(alreadyConfirmedData?.selectedOption)
    const handleChange = (event: any) => {
        console.log('(event.target as HTMLInputElement).value > ', (event.target as HTMLInputElement).value);
        selectedOptionUpdate(+(event.target as HTMLInputElement).value);
    };
    const { item } = props

    const schemaData = {
        '@context': 'https://schema.org',
        '@type': 'Event',
        name: item.name,
        keywords: item.tags?.join(', ') || ''
    }

    return (
        <Grid item xs={12} sm={6} md={4} lg={3} style={{ display: 'flex', justifyContent: 'center' }}>
            <Card
                sx={{
                    position: 'relative',
                    width: 250, // фиксированная ширина
                    height: 306, // фиксированная высота
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: `url(${item.picture})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        opacity: 0.1
                    },
                }}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        flexGrow: 1,
                    }}
                >
                    <Typography variant="h6" component="div" style={{ fontSize: '16px' }}>
                        {item.name}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: 1 }}>
                        {item.tags?.map((keyword, idx) => (
                            <Chip key={idx} label={keyword} sx={{ margin: 0.5 }} />
                        ))}
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: 1 }}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="options-radio-buttons-group-label"
                                name="options-radio-buttons-group"
                                value={selectedOption}
                                style={{ flexDirection: 'row' }}
                            >
                                {
                                    item.options.map((option: string, i: number) => {
                                        return <FormControlLabel
                                            checked={i === selectedOption}
                                            disabled={!getUserInfo() || !!alreadyConfirmedData}
                                            value={i}
                                            control={<Radio />}
                                            label={option}
                                            onChange={handleChange}
                                        />
                                    })
                                }
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: 1 }}>
                        <Button size='small'>
                            {/* <Link to={ERoutes.user}>{texts[lang].registerForTakeParticipation}</Link> */}
                            <Button color="success" component={Link} to={TG_PLATFORM_CH_URL} style={{ background: 'green', color: 'white', borderRadius: '5px' }}>
                                {texts[lang].takeParticipation}
                            </Button>
                        </Button>
                    </Box>
                </CardContent>
            </Card>
            <script type="application/ld+json">
                {JSON.stringify(schemaData)}
            </script>
        </Grid>
    )

    // return (
    //     <div className='eventPoll-grid-item adaptive'>
    //         <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    //             <Typography variant='h5'>
    //                 {item.name}
    //             </Typography>
    //         </CardContent>
    //         <CardActions style={{ display: 'flex', flexDirection: 'column' }}>
    //             <FormControl>
    //                 {/* <FormLabel id="options-radio-buttons-group-label">{texts[lang].options}</FormLabel> */}
    //                 <RadioGroup
    //                     aria-labelledby="options-radio-buttons-group-label"
    //                     name="options-radio-buttons-group"
    //                     value={selectedOption}
    //                 >
    //                     {
    //                         item.options.map((option: string, i: number) => {
    //                             return <FormControlLabel
    //                                 checked={i === selectedOption}
    //                                 disabled={!getUserInfo() || !!alreadyConfirmedData}
    //                                 value={i}
    //                                 control={<Radio />}
    //                                 label={option}
    //                                 onChange={handleChange}
    //                             />
    //                         })
    //                     }
    //                 </RadioGroup>
    //             </FormControl>
    //             {!getUserInfo() && (
    //                 <Button size='small'>
    //                     {/* <Link to={ERoutes.user}>{texts[lang].registerForTakeParticipation}</Link> */}
    //                     <Button color="success" component={Link} to={TG_PLATFORM_CH_URL} style={{ background: 'green', color: 'white', borderRadius: '5px' }}>
    //                         {texts[lang].takeParticipation}
    //                     </Button>
    //                 </Button>
    //             )}
    //             {getUserInfo() && typeof selectedOption === 'number' &&
    //                 !alreadyConfirmedData && (
    //                     <div>
    //                         <Button
    //                             size='small'
    //                             onClick={() => selectedOptionUpdate(undefined)}
    //                         >
    //                             <img style={{ width: '32px' }} src={arrowBackIcon} alt='confirm' />
    //                         </Button>
    //                         <Button
    //                             size='small'
    //                             onClick={() => props.confirmEventPoll(item.id!, selectedOption!)}
    //                         >
    //                             <img style={{ width: '32px' }} src={checkmarkIcon} alt='confirm' />
    //                         </Button>
    //                     </div>
    //                 )}
    //             <script type="application/ld+json">
    //                 {JSON.stringify(schemaData)}
    //             </script>
    //         </CardActions>
    //     </div>
    // )
}

export const EventPollsGrid = () => {
    const [eventPolls, eventPollsUpdate] = useState<{ count: number, list: IEventPoll[] }>({ count: 0, list: [] });
    // const [visibleEntities, setVisibleEntities] = useState<IEventPoll[]>([]);
    const [itemsCount, setItemsCount] = useState<number>(DEFAULT_GRID_ITEMS_COUNT);
    const loadMoreRef = useRef<HTMLDivElement | null>(null);

    const loadMore = () => {
        setItemsCount(itemsCount + DEFAULT_GRID_ITEMS_COUNT);
    };

    useEffect(() => {
        const loadEntities = () => {
            getEventPollsList();
        };
        loadEntities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsCount]);

    const getEventPollsList = async () => {
        if (eventPolls.list.length <= itemsCount - DEFAULT_GRID_ITEMS_COUNT) {
            requestHelper.request<true>({
                method: 'get',
                url: EApiRoutes.eventPoll,
                params: {
                    skip: itemsCount - DEFAULT_GRID_ITEMS_COUNT,
                    limit: DEFAULT_GRID_ITEMS_COUNT
                }
            }, { isListResponse: true }).then(({ count, list }) => {
                eventPollsUpdate({ count, list: [...eventPolls.list, ...list] as IEventPoll[] });
            })
        }
    }


    const confirmEventPoll = async (eventPollId: number, selectedOption: number) => {
        requestHelper.request({
            method: 'put',
            url: prepareUrlWithId(EApiRoutes.eventPollVote, eventPollId),
            headers: {
                authorization: getUserInfo().authToken
            },
            data: {
                selectedOption,
            }
        }).then((confirmResult: IEventPoll) => {
            console.log('confirmResult > ', confirmResult)
            if (confirmResult?.id) {
                hookHelper.useNotification({ notificationType: ENotificationType.success, message: texts[lang].successful })
                getEventPollsList()
            } else {
                hookHelper.useNotification({ notificationType: ENotificationType.error, message: confirmResult?.toString() })
            }
        })
    }

    // const onSubmit = async (eventsAsFacts: IEventAsFact[]): Promise<void> => {
    //     const preparedSolution: number[] = eventsAsFacts.map(e => e.id)

    //     requestHelper.request({
    //         url: prepareUrlWithId(EApiRoutes.seriesOfEventsByIdSolve, activeSeries.id),
    //         method: 'post',
    //         data: preparedSolution
    //     })
    //         .then((result: ISeriesOfEvent) => {
    //             if (result?.id) getSeriesOfEventsList()
    //         })
    // }

    // if (!seriesOfEventsList) getSeriesOfEventsList()

    if (!eventPolls.count) {
        getEventPollsList()
    }

    // return eventPollsList?.length ? <EventPollsGridItem item={eventPollsList[0]} /> : <Loader />
    return eventPolls.list?.length ? (
        <>
            <Grid container spacing={3} style={{ justifyContent: 'center' }}>
                {eventPolls.list.map((entity, index) => (
                    <EventPollsGridItem item={entity} key={index} confirmEventPoll={confirmEventPoll} />
                ))}
            </Grid>
            <div ref={loadMoreRef} style={{ height: '20px', marginTop: '16px' }}>
                {eventPolls.list.length < eventPolls.count && (
                    <Button style={{ padding: '5px' }} color='info' onClick={loadMore}>
                        ...
                    </Button>
                )}
            </div>
        </>
        // <Carousel
        //     // arrows={false}
        //     responsive={responsive}
        //     autoPlay={true}
        //     className='event-polls-carousel'
        // // customButtonGroup={<ButtonGroup />}
        // >
        //     {eventPollsList.map((item, i) => {
        //         return <EventPollsGridItem item={item} key={i} confirmEventPoll={confirmEventPoll} />
        //     })}
        // </Carousel>
    ) : <Loader />
}

import { DEFAULT_GRID_ITEMS_COUNT, EApiRoutes, EEventPollStatuses, ERoutes } from '../../configs/constants'
import { useEffect, useRef, useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { Button, Input, Table, TableRow, Typography } from '@mui/material'
import { Loader } from '../../components/Loader'
import { Edit, Save } from '@mui/icons-material'
import { Option, Select } from '@mui/joy'
import { AxiosRequestConfig } from 'axios'
import { prepareUrlWithId } from '../../helpers'
import { NavigateFunction, useNavigate } from 'react-router'
import { IEventPoll } from '../../types'
import { CreateEventPollPopper } from './EventPollPoppers'

const EventPollsRow = (props: {
    itemKey: number
    item: IEventPoll
    onUpdateSubmit: Function
    navigate: NavigateFunction
}) => {
    const { item, onUpdateSubmit } = props

    const [updatedItem, updatedItemUpdate] = useState<Pick<IEventPoll, 'id' | 'status'>>({
        id: item.id,
        status: item.status,
    });

    return (
        <TableRow style={{
            background: props.itemKey % 2 ? 'rgb(162,162,208, 0.1)' : 'white',
        }}>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>
                {
                    [EEventPollStatuses.pending].includes(item.status!)
                        ? (
                            <Select
                                placeholder='Status'
                                value={updatedItem.status}
                                onChange={(_, newValue): any => updatedItemUpdate({
                                    ...updatedItem,
                                    status: newValue as EEventPollStatuses
                                })}>
                                {
                                    Object.values(EEventPollStatuses).map((status: EEventPollStatuses) => (
                                        <Option value={status}>{status}</Option>
                                    ))
                                }
                            </Select>
                        )
                        : item.status
                }
            </td>
            <td>{item.datetimeOfConfirmation?.toString()}</td>
            {/* <td>{item.voteAvailableUntilDate?.toString()}</td>
            <td>{item.initialBankAmount}</td>
            <td>{item.amountToVote}</td>
            <td>{item.comissionPercent}</td>
            <td>{item.options}</td> */}
            <td>{item.countedAfterComplete}</td>
            {/* <td>{item.createdAt?.toString()}</td>
            <td>{item.confirmedAt?.toString()}</td> */}
            <td>{item.confirmedResultAsNumber}</td>
            <td>{item.totalBankAmount}</td>
            {/* <td>{item.amountForWinner}</td> */}
            <td>{item.participants?.length}</td>
            <td>
                {
                    !(
                        item.status?.toString() === updatedItem.status?.toString()
                    ) && (
                        <Button
                            onClick={() => onUpdateSubmit(updatedItem)}
                        ><Save color='success' /></Button>
                    )
                }
                <Button
                    onClick={() => props.navigate(prepareUrlWithId(ERoutes.adminManageEventPoll, item.id!))}
                ><Edit color='info' /></Button>
            </td>
        </TableRow>
    )
}

export const ManageEventPolls = () => {
    const [eventPolls, eventPollsUpdate] = useState<{ count: number, list: IEventPoll[] }>({ count: 0, list: [] });
    // const [visibleEntities, setVisibleEntities] = useState<IEventPoll[]>([]);
    const [filter, filterUpdate] = useState<Pick<IEventPoll, 'status'>>({} as any)
    const [itemsCount, setItemsCount] = useState<number>(DEFAULT_GRID_ITEMS_COUNT);
    const loadMoreRef = useRef<HTMLDivElement | null>(null);

    const loadMore = () => {
        setItemsCount(itemsCount + DEFAULT_GRID_ITEMS_COUNT);
    };

    useEffect(() => {
        const loadMoreItems = async () => {
            const { count, list } = await getEventPollsList();
            eventPollsUpdate({ count, list: [...eventPolls.list, ...list] as IEventPoll[] });
        };

        if (itemsCount > 0) {
            loadMoreItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsCount]);

    useEffect(() => {
        setItemsCount(DEFAULT_GRID_ITEMS_COUNT);

        const loadMoreItems = async () => {
            const { count, list } = await getEventPollsList();
            eventPollsUpdate({ count, list: list as IEventPoll[] });
        };

        loadMoreItems();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const getEventPollsList = async () => {
        return requestHelper.request<true>({
            method: 'get',
            url: EApiRoutes.eventPoll,
            params: {
                skip: itemsCount - DEFAULT_GRID_ITEMS_COUNT,
                limit: DEFAULT_GRID_ITEMS_COUNT,
                filter: JSON.stringify(filter)
            }
        }, { isListResponse: true })
        // if (eventPolls.list.length <= itemsCount - DEFAULT_GRID_ITEMS_COUNT) {

        // }
    }

    const navigate = useNavigate()
    const onSaveSubmit = async (item: Partial<IEventPoll>) => {
        const { id, ...preparedItem } = item
        const preparedParams: AxiosRequestConfig = id
            ? {
                method: 'patch',
                url: prepareUrlWithId(EApiRoutes.adminUpdateEventPollById, id),
                data: preparedItem
            } : {
                method: 'post',
                url: EApiRoutes.eventPoll,
                data: preparedItem,
            }

        requestHelper.request(preparedParams).then(() => {
            getEventPollsList()
        })
    }

    if (!eventPolls.count) {
        getEventPollsList()
    }

    return (
        <div>
            <Typography variant='h6'>События-Опросы</Typography>
            <CreateEventPollPopper onSaveSubmit={onSaveSubmit} />
            < br /><br />
            <Input
                placeholder='Filter by status...'
                value={filter.status}
                onChange={(e): any => filterUpdate(e.target.value ? {
                    status: e.target.value
                } : {} as any)}
            />
            {
                eventPolls.list?.length
                    ? (
                        <>
                            <Table aria-label="basic table">
                                <thead>
                                    <tr>
                                        <td>id</td>
                                        <td>name</td>
                                        <td>status</td>
                                        <td>datetimeOfConfirmation</td>
                                        {/* <td>voteAvailableUntilDate</td>
                        <td>initialBankAmount</td>
                        <td>amountToVote</td>
                        <td>comissionPercent</td>
                        <td>options</td> */}
                                        <td>countedAfterComplete</td>
                                        {/* <td>createdAt</td>
                        <td>confirmedAt</td> */}
                                        <td>confirmedResultAsNumber</td>
                                        <td>totalBankAmount</td>
                                        {/* <td>amountForWinner</td> */}
                                        <td>participants</td>
                                        <td>Actions</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        eventPolls.list?.map((item, i) => (
                                            <EventPollsRow
                                                key={i}
                                                itemKey={i}
                                                item={item}
                                                navigate={navigate}
                                                onUpdateSubmit={onSaveSubmit}
                                            />
                                        ))
                                    }
                                </tbody>
                            </Table>
                            <div ref={loadMoreRef} style={{ height: '20px', marginTop: '16px' }}>
                                {eventPolls.list.length < eventPolls.count && (
                                    <Button style={{ padding: '5px' }} color='info' onClick={loadMore}>
                                        ...
                                    </Button>
                                )}
                            </div>
                        </>
                    )
                    : <Loader />
            }
        </div >
    )
}

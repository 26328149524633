import { EApiRoutes, EWalletTransactionStatuses } from '../../configs/constants'
import { useEffect, useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { Button, Table, Typography } from '@mui/material'
import { Loader } from '../../components/Loader'
import { Save } from '@mui/icons-material'
import { Option, Select } from '@mui/joy'
import { AxiosRequestConfig } from 'axios'
import { IUserInfo, IUserInfoFromAdmin, IUserTransaction } from '../../types'
import { NavigateFunction, useNavigate } from 'react-router'

const UserTransactionsRow = (props: {
    item: IUserTransaction
    onUpdateSubmit: Function
    navigate: NavigateFunction
}) => {
    const { item, onUpdateSubmit } = props

    const [updatedItem, updatedItemUpdate] = useState<Pick<IUserTransaction, 'id' | 'status'>>({
        id: item.id,
        status: item.status,
    });

    return (
        <tr>
            <td>{item.id}</td>
            <td>{item.user.id}</td>
            <td>{item.createdAt}</td>
            <td>{item.updatedAt}</td>
            <td>{item.amount}</td>
            <td>{item.balanceBefore}</td>
            <td>{item.balanceAfter}</td>
            <td>
                {
                    item.status !== EWalletTransactionStatuses.completed
                        ? (
                            <Select
                                placeholder='Status'
                                value={updatedItem.status}
                                onChange={(_, newValue): any => updatedItemUpdate({
                                    ...updatedItem,
                                    status: newValue as any
                                })}>
                                {
                                    Object.values(EWalletTransactionStatuses).map((status: EWalletTransactionStatuses) => (
                                        <Option value={status}>{status}</Option>
                                    ))
                                }
                            </Select>
                        )
                        : updatedItem.status
                }
            </td>
            <td>{item.transferType}</td>
            <td>{JSON.stringify(item.details)}</td>
            <td>
                {
                    !(
                        item.status?.toString() === updatedItem.status?.toString()
                    ) && (
                        <Button
                            onClick={() => onUpdateSubmit(updatedItem)}
                        ><Save color='success' /></Button>
                    )
                }
            </td>
        </tr>
    )
}

export const UserTransactions = (props: { user: IUserInfo }) => {
    const [itemsList, itemsListUpdate] = useState<IUserTransaction[]>(null as any)
    const [filter] = useState<{ user: number, status: EWalletTransactionStatuses }>({
        user: props.user.id
    } as any)

    const getItemsList = () => {
        const preparedParams: AxiosRequestConfig = {
            method: 'get',
            url: EApiRoutes.walletTransactions,
            params: { filter: JSON.stringify(filter) }
        }
        requestHelper
            .request(preparedParams)
            .then((items) => {
                itemsListUpdate(items)
            })
    }
    useEffect(getItemsList, [filter]);

    const navigate = useNavigate()
    const onSaveSubmit = (item: Partial<IUserInfoFromAdmin>) => {
        if (item.confirmPassword && item.confirmPassword !== item.password) {
            throw new Error(`Passwords confirmation doesn't match`)
        }

        requestHelper.request({
            method: 'post',
            // url: EApiRoutes.adminWalletTranasactions,
            data: item,
        }).then((createdOrUpdatedItem: IUserInfoFromAdmin) => {
            getItemsList()
        })
    }

    if (!itemsList) getItemsList()

    return itemsList ? (
        <div>
            <Typography variant='h6'>Транзакции пользователя</Typography>
            {/* <Input
                placeholder='Filter by username...'
                value={filter.username}
                onChange={(e): any => filterUpdate(e.target.value ? {
                    username: e.target.value
                } : {} as any)}
            /> */}
            <Table aria-label="basic table">
                <thead>
                    <tr>
                        <td>id</td>
                        <td>user</td>
                        <td>createdAt</td>
                        <td>updatedAt</td>
                        <td>amount</td>
                        <td>balanceBefore</td>
                        <td>balanceAfter</td>
                        <td>status</td>
                        <td>transferType</td>
                        <td>details</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        itemsList.map((item: IUserTransaction, i: number) => (
                            <UserTransactionsRow
                                key={i}
                                item={item}
                                navigate={navigate}
                                onUpdateSubmit={onSaveSubmit}
                            />
                        ))
                    }
                </tbody>
            </Table>
        </div >
    ) : <Loader />
    // return <Loader />
}

import { Alert } from '@mui/material'
import { ENotificationType } from '../configs/constants'

const severityNotificationTypeMap: { [key in ENotificationType]: string } = {
    [ENotificationType.error]: 'error',
    [ENotificationType.success]: 'success',
    [ENotificationType.info]: 'info',
    [ENotificationType.warning]: 'warning',
}
const notificationTimeout = 5000

export const Notification = (props: {
    notificationType: ENotificationType,
    message: string,
    unsetNotification: Function
}) => {
    setTimeout(() => {
        props.unsetNotification({
            notificationType: null,
            message: ''
        })
    }, notificationTimeout)
    return (
        <Alert
            severity={severityNotificationTypeMap[props.notificationType] as any}
            style={{ position: 'fixed', marginLeft: '75%', width: '100%' }}
        >{props.message}</Alert>
    )
}

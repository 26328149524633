import { EApiRoutes, ERoutes } from '../../configs/constants'
import { useEffect, useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { Input, Table, Typography } from '@mui/material'
import { Loader } from '../../components/Loader'
import { AxiosRequestConfig } from 'axios'
import { prepareUrlWithId } from '../../helpers'
import { IUserInfo, IUserVote } from '../../types'
import { Link } from 'react-router-dom'

const UserVotesRow = (props: {
    item: IUserVote
}) => {
    const { item } = props

    return (
        <tr>
            <td>{item.id}</td>
            <td>{item.createdAt}</td>
            <td>{item.selectedOption}</td>
            <td>{item.user.username}</td>
            <td>
                <Link to={prepareUrlWithId(ERoutes.adminManageEventPoll, item.eventPoll.id)}>{item.eventPoll.name}</Link>
            </td>
        </tr>
    )
}

export const UserVotes = (props: { user: IUserInfo }) => {
    const [itemsList, itemsListUpdate] = useState<IUserVote[]>(null as any)
    const [filter, filterUpdate] = useState<{ user: number, eventPoll: number }>({
        user: props.user.id,
    } as any)

    const getItemsList = () => {
        const preparedParams: AxiosRequestConfig = {
            method: 'get',
            url: EApiRoutes.eventPollsVotes,
            params: { filter: JSON.stringify(filter) }
        }
        requestHelper
            .request(preparedParams)
            .then((items) => {
                itemsListUpdate(items)
            })
    }
    useEffect(getItemsList, [filter]);

    if (!itemsList) getItemsList()

    return itemsList ? (
        <div>
            <Typography variant='h6'>Голосования пользователя</Typography>
            <Input
                placeholder='Filter by eventPoll...'
                value={filter.eventPoll || 0}
                onChange={(e): any => filterUpdate(e.target.value ? {
                    eventPoll: +e.target.value
                } : {} as any)}
            />
            <Table aria-label="basic table">
                <thead>
                    <tr>
                        <td>id</td>
                        <td>date</td>
                        <td>selectedOption</td>
                        <td>user</td>
                        <td>eventPoll</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        itemsList.map((item: IUserVote, i: number) => (
                            <UserVotesRow
                                key={i}
                                item={item}
                            />
                        ))
                    }
                </tbody>
            </Table>
        </div >
    ) : <Loader />
    // return <Loader />
}

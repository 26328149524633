import {
    Box, Button, CardContent, Container, FormControl, Input, InputLabel, Paper, Tab,
    Table, TableBody, TableCell, TableContainer, TableRow,
    Tabs, Typography
} from '@mui/material'
import { Link, useNavigate, useParams } from 'react-router-dom'

import texts from '../../configs/texts'
import { EApiRoutes, EEventPollStatuses, ERoutes, lang } from '../../configs/constants'
import React, { useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { Loader } from '../../components/Loader'
import { a11yProps, TabPanel } from '../../components/TabPanel'
import { IEventPoll } from '../../types'
import { getBaseEventPoll } from '../../helpers/eventPoll';
import { prepareUrlWithId } from '../../helpers'
import { EventPollVotes } from './EventPollVotes'
import { EventPollCancelPopper, EventPollCompletePopper } from '../Poppers'
import { MarkWinnerPayoutPopper } from './EventPollPoppers'
import { IEventPollUpdateWinnerPaymentParams } from '../../types/EventPoll'
import { Update } from '@mui/icons-material'

const propsToHide: string[] = [
    'updatedBy', 'confirmedBy', 'countryCodes', 'minParticipantsCount', 'baseTimezone',
]
const propsToEdit: string[] = [
    'tags', 'additionalInfo', 'remarks',
]

const ItemInfoCard = (props: { item: Partial<IEventPoll> }) => {
    const { item } = props
    const navigate = useNavigate()

    const [itemToUpdate, setItemToUpdate] = useState<Pick<IEventPoll, 'tags' | 'additionalInfo' | 'remarks'>>({})

    const eventPollCompleteSubmit = (eventPollId: number, data: Pick<IEventPoll, 'confirmedResultAsNumber' | 'remarks'>) => {
        requestHelper.request({
            method: 'post',
            url: prepareUrlWithId(EApiRoutes.adminEventPollComplete, item.id!),
            data: {
                eventPollId: eventPollId,
                resultOption: data.confirmedResultAsNumber,
                remarks: data.remarks,
            }
        }).then(() => navigate(0))
    }

    const eventPollUpdateSubmit = () => {
        requestHelper.request({
            method: 'patch',
            url: prepareUrlWithId(EApiRoutes.adminUpdateEventPollById, item.id!),
            data: itemToUpdate
        }).then(() => navigate(0))
    }

    const eventPollCancelSubmit = () => {
        requestHelper.request({
            method: 'patch',
            url: prepareUrlWithId(EApiRoutes.adminUpdateEventPollById, item.id!),
            data: { status: 'canceledByReason' }
        }).then(() => navigate(0))
    }

    const markWinnerPayoutPopperSubmit = async (data: IEventPollUpdateWinnerPaymentParams) => {
        requestHelper.request({
            method: 'post',
            url: prepareUrlWithId(EApiRoutes.adminEventPollUpdateWinnerPayment, item.id!, { userId: data.userId.toString() }),
            data: {
                winAmountPaid: data.winAmountPaid,
                payoutDetails: data.payoutDetails,
                payoutScreenshot: data.payoutScreenshot,
                payoutDate: data.payoutDate,
            }
        }).then(() => navigate(0))
    }

    return (
        <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* <Typography variant='h5'>
                {item.username}
            </Typography> */}
            <br /><br />
            <TableContainer component={Paper} style={{ width: '50%' }}>
                <Table>
                    <TableBody>
                        {
                            Object.keys(item).filter(p => !propsToHide.includes(p)).map((prop: string, i) => {
                                let tableCellWithValue = <TableCell align='right'>{
                                    Array.isArray((item as any)[prop])
                                        ? (item as any)[prop].join(',')
                                        : (item as any)[prop]?.toString()
                                }</TableCell>

                                if (propsToEdit.includes(prop) && ![EEventPollStatuses.completed, EEventPollStatuses.canceledByReason].includes(item.status!)) {
                                    tableCellWithValue = <TableCell align='right'>
                                        <FormControl>
                                            <InputLabel id={`${(texts[lang] as any)[prop]}-label`}>{(texts[lang] as any)[prop]}</InputLabel>
                                            <Input
                                                placeholder={(texts[lang] as any)[prop]}
                                                value={(itemToUpdate as any)[prop] || (item as any)[prop]}
                                                onChange={(e): any => setItemToUpdate({
                                                    ...itemToUpdate,
                                                    [prop]: ['tags'].includes(prop)
                                                        ? e.target.value.split(',')
                                                        : e.target.value
                                                })}
                                            />
                                        </FormControl>
                                    </TableCell>
                                }

                                if (prop === 'options') {
                                    tableCellWithValue = <TableCell align='right'>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', }}>
                                            {item.options?.map((option, i) => {
                                                return <div>{i}: {option}</div>
                                            })}
                                        </div>
                                    </TableCell>
                                } else if (prop === 'platforms') {
                                    tableCellWithValue = <TableCell align='right'>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', }}>
                                            {/* {item.platforms?.map((option, i) => {
                                                return <div>{i}: {option}</div>
                                            })} */}
                                            {
                                                Object.keys(item.platforms || {}).map((p, j) => {
                                                    return (
                                                        <>
                                                            {p}: {(item.platforms as any)[p].published ? 'Опубликовано' : 'Нет'}
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </TableCell>
                                } else if (prop === 'participants') {
                                    tableCellWithValue = <TableCell align='right'>{
                                        item.participants?.map(p => {
                                            return (
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Link to={prepareUrlWithId(ERoutes.adminManageUser, p.user.id)}>{p.user.username}</Link>
                                                    <div></div>
                                                </div>
                                            )
                                        })
                                    }</TableCell>
                                } else if (prop === 'winners') {
                                    tableCellWithValue = <TableCell align='right'>{
                                        item.winners!.map(p => {
                                            // console.log('debug winners > ', item.id, ' >> ', p.user.id)
                                            return (
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                                                    <Link to={prepareUrlWithId(ERoutes.adminManageUser, p.user.id)}>{p.user.username}</Link>
                                                    <div>{p.winnerDetails?.winAmountPaid
                                                        ? <Button type='button' color='success' disabled={false}>{texts[lang].paid}</Button>
                                                        : <MarkWinnerPayoutPopper
                                                            eventPollId={item.id!}
                                                            userId={p.user.id}
                                                            onSaveSubmit={markWinnerPayoutPopperSubmit}
                                                        />
                                                    }</div>
                                                </div>
                                            )
                                        })
                                    }</TableCell>
                                }

                                return (
                                    <TableRow key={i}>
                                        <TableCell align='right'>{(texts[lang] as any)[prop] || prop}</TableCell>
                                        {tableCellWithValue}
                                    </TableRow>
                                )
                            })
                        }
                        <TableRow>
                            <TableCell align='right'>
                                {
                                    Object.keys(itemToUpdate).length
                                        ? <Button type='button' onClick={() => eventPollUpdateSubmit()}><Update color='primary' /></Button>
                                        : ''
                                }
                                {
                                    [EEventPollStatuses.waitingForConfirmation].includes(item.status as EEventPollStatuses)
                                        ? <EventPollCompletePopper eventPollId={item.id!} submitUpdate={eventPollCompleteSubmit} />
                                        : ''
                                }
                                {
                                    [EEventPollStatuses.onVote].includes(item.status as EEventPollStatuses)
                                        ? <EventPollCancelPopper eventPollId={item.id!} submitUpdate={eventPollCancelSubmit} />
                                        : ''
                                }
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </CardContent>
    )
}

export const EventPoll = () => {
    const { id } = useParams()
    const [itemData, updateItemData] = useState<Partial<IEventPoll>>({
        ...getBaseEventPoll()
    })
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    if (!itemData?.id) {
        requestHelper.request({
            method: 'get',
            url: prepareUrlWithId(EApiRoutes.eventPollById, id?.toString()!),
        }).then((data) => updateItemData(data))
    }

    return itemData?.id ? (
        <Container>
            <Typography variant='h5' component='div' textAlign='center'>{itemData.name}</Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="login-register">
                    <Tab label={texts[lang].information} {...a11yProps(0)} />
                    <Tab label={texts[lang].votes} {...a11yProps(1)} />
                    {/* <Tab label={texts[lang].transactions} {...a11yProps(2)} /> */}
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <ItemInfoCard item={itemData} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <EventPollVotes item={itemData as IEventPoll} />
            </TabPanel>
            {/* <TabPanel value={tabValue} index={2}>
                <EventPollTransactions eventPoll={itemData as IEventPoll} />
            </TabPanel> */}
        </Container>
    ) : <Loader />
}

import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, List, ListItem } from '@mui/material';
import texts from '../../configs/texts';
import { EApiRoutes, lang } from '../../configs/constants';
import { requestHelper } from '../../helpers/requestHelper';

// Типы данных для Terms
interface TermsItem {
    date: string;
    agreements: {
        title: string;
        list: string[];
    }[];
}

export function UserTermsPage() {
    const [termsData, setTermsData] = useState<TermsItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchTerms = async () => {
            try {
                setTermsData(await requestHelper.request({
                    method: 'get',
                    url: EApiRoutes.dictTerms
                }));
            } catch (error) {
                console.error('Error fetching Terms data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTerms();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Box>
            <Typography variant='h4' gutterBottom>
                {texts[lang].userTerms}
            </Typography>
            {termsData.map((terms, index) => (
                <Box key={index} mb={4}>
                    <Typography variant='h6'>{texts[lang].updatedAt}: {terms.date}</Typography>
                    {terms.agreements.map((agreement, idx) => (
                        <Box key={idx} mt={2}>
                            <Typography variant='h5'>{agreement.title}</Typography>
                            <List>
                                {agreement.list.map((item, id) => (
                                    <ListItem key={id}>
                                        {id + 1}. {item}
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    ))}
                </Box>
            ))}
        </Box>
    );
}

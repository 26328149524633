import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import texts from '../../configs/texts';
import { EApiRoutes, lang } from '../../configs/constants';
import { requestHelper } from '../../helpers/requestHelper';

// Типы данных для Promotions
interface LevelsAndRewardsItem {
    description: string;
    baseRewards: Record<string, string[]>
}

export function LevelsAndRewards() {
    const [levelsAndRewardsData, setLevelsAndRewardsData] = useState<LevelsAndRewardsItem | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLevelsAndRewardsData(await requestHelper.request({
                    method: 'get',
                    url: EApiRoutes.dictLevelAndRewards
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                {texts[lang].levelAndRewards}
            </Typography>
            {levelsAndRewardsData ? (
                <>
                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: levelsAndRewardsData.description }} />
                    <Typography variant='h6'>
                        {texts[lang].tableOfLevelAndRewards}
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align='right'>{texts[lang].level}</TableCell>
                                <TableCell align='right'>{texts[lang].rewards}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(levelsAndRewardsData.baseRewards).map(lv => {
                                return (
                                    <TableRow>
                                        <TableCell align='right'>{lv}</TableCell>
                                        <TableCell align='right'>{levelsAndRewardsData.baseRewards[lv].map(rw => (
                                            <p>{rw}</p>
                                        ))}</TableCell>
                                    </TableRow>
                                )
                            })}

                            <TableRow>
                                <TableCell align='right'>...</TableCell>
                                <TableCell align='right'>{texts[lang].infoIsAvailableForRegisteredUsers}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>
            ) : (
                'Нет данных для отображения'
            )}
        </Box>
    );
}

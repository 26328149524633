import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { InputLabel } from '@mui/material';
import texts from '../configs/texts';
import { lang } from '../configs/constants';

// const defaultValue = dayjs('2022-04-17T15:30');

export default function DateTimePicker(props: { val: Date | string, onChange: any, labelName: string }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <InputLabel style={{ marginTop: '-20px' }} id={`${texts[lang][props.labelName]}-label`}>{props.labelName}</InputLabel>
            <DateTimeField value={dayjs(props.val)} onChange={props.onChange} />
        </LocalizationProvider>
    );
}

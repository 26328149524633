import React, { useState } from 'react';
import { IEventPoll } from '../../types';
import { getBaseEventPoll } from '../../helpers/eventPoll';
import addIcon from '../../images/add_icon.png'
import { Box, Button, FormControl, FormGroup, Input, InputLabel, Popper, Switch, TextField } from '@mui/material';
import { Select, Option } from '@mui/joy'
import texts from '../../configs/texts';
import { EEventPollStatuses, lang } from '../../configs/constants';
import DateTimePicker from '../../components/DateTimePicker';
import { Save } from '@mui/icons-material';
import { IEventPollUpdateWinnerPaymentParams } from '../../types/EventPoll';

export const CreateEventPollPopper = (props: {
    onSaveSubmit: (item: Partial<IEventPoll>) => Promise<void>
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [newItem, newItemUpdate] = useState<Partial<IEventPoll>>({ ...getBaseEventPoll() })

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const onSave = () => {
        props.onSaveSubmit({ ...newItem, spheres: [] }).then(() => {
            newItemUpdate({ ...getBaseEventPoll() })
        })
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div>
            <button aria-describedby={id} type="button" onClick={handleClick}>
                <img style={{ width: '32px' }} src={addIcon} alt='confirm' />
            </button>
            <Popper
                id={id}
                open={open}
                placement='bottom'
                anchorEl={anchorEl}
                style={{ padding: '45px' }}
            >
                <Box
                    sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}
                    style={{
                        padding: '25px',
                        border: '1px solid green',
                        borderRadius: '5%'
                    }}
                >
                    <FormGroup className='create-form-group'>
                        <FormControl>
                            <InputLabel id={`${texts[lang].name}-label`}>{texts[lang].name}</InputLabel>
                            <Input
                                placeholder={texts[lang].name}
                                value={newItem.name}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    name: e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel style={{ marginTop: '-30px', }} id={`${texts[lang].status}-label`}>{texts[lang].status}</InputLabel>
                            <Select
                                value={newItem.status}
                                onChange={(_, newValue): any => newItemUpdate({
                                    ...newItem,
                                    status: newValue as EEventPollStatuses
                                })}>
                                {
                                    Object.values(EEventPollStatuses).map((status: EEventPollStatuses) => (
                                        <Option value={status}>{status}</Option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl>
                            {/* <Input
                        type='date'
                        title={texts[lang].voteAvailableUntilDate}
                        placeholder={texts[lang].voteAvailableUntilDate}
                        value={newItem.voteAvailableUntilDate}
                        onChange={(e): any => newItemUpdate({
                            ...newItem,
                            voteAvailableUntilDate: e.target.value
                        })}
                    /> */}
                            {/* <InputLabel id={`${texts[lang].voteAvailableUntilDate}-label`}>{texts[lang].voteAvailableUntilDate}</InputLabel> */}
                            <DateTimePicker
                                val={newItem.voteAvailableUntilDate!}
                                labelName={texts[lang].voteAvailableUntilDate}
                                onChange={(e: any): any => {
                                    // console.log('e >> ', e, ' > ', e.toString());
                                    return newItemUpdate({
                                        ...newItem,
                                        voteAvailableUntilDate: e.toString()
                                    })
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].wayOfResultConfirmation}-label`}>{texts[lang].wayOfResultConfirmation}</InputLabel>
                            <Input
                                placeholder={texts[lang].wayOfResultConfirmation}
                                value={newItem.wayOfResultConfirmation}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    wayOfResultConfirmation: e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            {/* <Input
                        type='date'
                        title={texts[lang].datetimeOfConfirmation}
                        placeholder={texts[lang].datetimeOfConfirmation}
                        value={newItem.datetimeOfConfirmation}
                        onChange={(e): any => newItemUpdate({
                            ...newItem,
                            datetimeOfConfirmation: e.target.value
                        })}
                    /> */}
                            <DateTimePicker
                                val={newItem.datetimeOfConfirmation!}
                                labelName={texts[lang].datetimeOfConfirmation}
                                onChange={(e: any): any => newItemUpdate({
                                    ...newItem,
                                    datetimeOfConfirmation: e.toString()
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].initialBankAmount}-label`}>{texts[lang].initialBankAmount}</InputLabel>
                            <Input
                                type='number'
                                title={texts[lang].initialBankAmount}
                                placeholder={texts[lang].initialBankAmount}
                                value={newItem.initialBankAmount}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    initialBankAmount: +e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].amountToVote}-label`}>{texts[lang].amountToVote}</InputLabel>
                            <TextField
                                title={texts[lang].amountToVote}
                                placeholder={texts[lang].amountToVote}
                                value={newItem.amountToVote}
                                type='number'
                                inputProps={{
                                    step: '0.1'
                                }}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    amountToVote: +e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].comissionPercent}-label`}>{texts[lang].comissionPercent}</InputLabel>
                            <Input
                                title={texts[lang].comissionPercent}
                                placeholder={texts[lang].comissionPercent}
                                value={newItem.comissionPercent}
                                type='number'
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    comissionPercent: +e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].orgPercent}-label`}>{texts[lang].orgPercent}</InputLabel>
                            <Input
                                title={texts[lang].orgPercent}
                                placeholder={texts[lang].orgPercent}
                                value={newItem.orgPercent}
                                type='number'
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    orgPercent: +e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].options}-label`}>{texts[lang].options}</InputLabel>
                            <Input
                                placeholder={texts[lang].options}
                                value={newItem.options?.join(',')}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    options: e.target.value.split(',')
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].minParticipantsCount}-label`}>{texts[lang].minParticipantsCount}</InputLabel>
                            <Input
                                title={texts[lang].minParticipantsCount}
                                placeholder={texts[lang].minParticipantsCount}
                                value={newItem.minParticipantsCount}
                                type='number'
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    minParticipantsCount: +e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].countryCodes}-label`}>{texts[lang].countryCodes}</InputLabel>
                            <Input
                                placeholder={texts[lang].countryCodes}
                                value={newItem.countryCodes?.join(',')}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    countryCodes: Array.from(new Set(e.target.value.split(',')))
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].platforms}-label`}>{texts[lang].platforms}</InputLabel>
                            <Input
                                placeholder={texts[lang].platforms}
                                value={Object.keys(newItem.platforms || {}).join(',')}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    platforms: Array.from(new Set(e.target.value.split(',')))
                                        .reduce((acc: any, next: string) => {
                                            acc[next] = {}
                                            return acc
                                        }, {})
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].tags}-label`}>{texts[lang].tags}</InputLabel>
                            <Input
                                placeholder={texts[lang].tags}
                                value={(newItem.tags || []).join(',')}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    tags: Array.from(new Set(e.target.value.split(',')))
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].picture}-label`}>{texts[lang].picture}</InputLabel>
                            <Input
                                placeholder={texts[lang].picture}
                                value={newItem.picture}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    picture: e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].additionalInfo}-label`}>{texts[lang].additionalInfo}</InputLabel>
                            <Input
                                placeholder={texts[lang].additionalInfo}
                                value={newItem.additionalInfo}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    additionalInfo: e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].maxVotes}-label`}>{texts[lang].maxVotes}</InputLabel>
                            <Input
                                type='number'
                                placeholder={texts[lang].maxVotes}
                                value={newItem.maxVotes}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    maxVotes: +e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].externalCurrency}-label`}>{texts[lang].externalCurrency}</InputLabel>
                            <Input
                                placeholder={texts[lang].externalCurrency}
                                value={newItem.externalCurrency}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    externalCurrency: e.target.value
                                })}
                            />
                        </FormControl>
                        {
                            (newItem.name) && (
                                <Button onClick={() => onSave()}><Save color='success' /></Button>
                            )
                        }
                    </FormGroup>
                </Box>
            </Popper>
        </div>
    );
}

export const MarkWinnerPayoutPopper = (props: {
    eventPollId: number
    userId: number
    onSaveSubmit: (data: IEventPollUpdateWinnerPaymentParams) => Promise<void>
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [newItem, newItemUpdate] = useState<Pick<
        IEventPollUpdateWinnerPaymentParams,
        'winAmountPaid' | 'payoutDetails' | 'payoutScreenshot' | 'payoutDate'
    >>({
        winAmountPaid: false,
        payoutDetails: '',
        payoutScreenshot: '',
        payoutDate: new Date(),
    })

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const onSave = () => {
        props.onSaveSubmit({
            ...newItem,
            userId: props.userId,
            eventPollId: props.eventPollId
        }).then(() => {
            setAnchorEl(null)
        })
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div>
            <button aria-describedby={id} type="button" onClick={handleClick}>
                <Button color='secondary'>{texts[lang].makePayout}</Button>
                {/* <img style={{ width: '32px' }} src={addIcon} alt='confirm' /> */}
            </button>
            <Popper
                id={id}
                open={open}
                placement='bottom'
                anchorEl={anchorEl}
                style={{ padding: '45px' }}
            >
                <Box
                    sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}
                    style={{
                        padding: '25px',
                        border: '1px solid green',
                        borderRadius: '5%'
                    }}
                >
                    <FormGroup className='create-form-group'>
                        <FormControl>
                            {/* <InputLabel id={`${texts[lang].paid}-label`}>{texts[lang].paid}</InputLabel> */}
                            <Switch
                                checked={newItem.winAmountPaid}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    winAmountPaid: e.target.checked
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].payoutDetails}-label`}>{texts[lang].payoutDetails}</InputLabel>
                            <Input
                                placeholder={texts[lang].payoutDetails}
                                value={newItem.payoutDetails}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    payoutDetails: e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].payoutScreenshot}-label`}>{texts[lang].payoutScreenshot}</InputLabel>
                            <Input
                                placeholder={texts[lang].payoutScreenshot}
                                value={newItem.payoutScreenshot}
                                onChange={(e): any => newItemUpdate({
                                    ...newItem,
                                    payoutScreenshot: e.target.value
                                })}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id={`${texts[lang].date}-label`}>{texts[lang].date}</InputLabel>
                            <DateTimePicker
                                val={newItem.payoutDate!}
                                labelName={texts[lang].date}
                                onChange={(e: any): any => newItemUpdate({
                                    ...newItem,
                                    payoutDate: e.toString()
                                })}
                            />
                        </FormControl>
                        {
                            (newItem.winAmountPaid) && (
                                <Button onClick={() => onSave()}><Save color='success' /></Button>
                            )
                        }
                    </FormGroup>
                </Box>
            </Popper>
        </div>
    );
}


import { EApiRoutes, ERoutes } from '../../configs/constants'
import { useEffect, useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { Input, Table, Typography } from '@mui/material'
import { Loader } from '../../components/Loader'
import { AxiosRequestConfig } from 'axios'
import { prepareUrlWithId } from '../../helpers'
import { IUserVote } from '../../types'
import { Link } from 'react-router-dom'
import { IEventPoll } from '../../types'

const EventPollVotesRow = (props: {
    item: IUserVote
}) => {
    const { item } = props

    return (
        <tr>
            <td>{item.id}</td>
            <td>{item.createdAt}</td>
            <td>{item.selectedOption} ({item.eventPoll.options[item.selectedOption]})</td>
            <td>
                <Link to={prepareUrlWithId(ERoutes.adminManageUser, item.user.id)}>{item.user.username}</Link>
            </td>
            <td>
                <Link to={prepareUrlWithId(ERoutes.adminManageEventPoll, item.eventPoll.id)}>{item.eventPoll.name}</Link>
            </td>
        </tr>
    )
}

export const EventPollVotes = (props: { item: IEventPoll }) => {
    const [itemsList, itemsListUpdate] = useState<IUserVote[]>(null as any)
    const [filter, filterUpdate] = useState<{ user: number, eventPoll: number }>({
        eventPoll: props.item.id,
    } as any)

    const getItemsList = () => {
        const preparedParams: AxiosRequestConfig = {
            method: 'get',
            url: EApiRoutes.eventPollsVotes,
            params: { filter: JSON.stringify(filter) }
        }
        requestHelper
            .request(preparedParams)
            .then((items) => {
                itemsListUpdate(items)
            })
    }
    useEffect(getItemsList, [filter]);

    if (!itemsList) getItemsList()

    return itemsList ? (
        <div>
            <Typography variant='h6'>Голосования события</Typography>
            <Input
                placeholder='Filter by user...'
                value={filter.user || ''}
                onChange={(e): any => filterUpdate(e.target.value ? {
                    user: +e.target.value
                } : {} as any)}
            />
            <Table aria-label="basic table">
                <thead>
                    <tr>
                        <td>id</td>
                        <td>date</td>
                        <td>selectedOption</td>
                        <td>user</td>
                        <td>eventPoll</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        itemsList.map((item: IUserVote, i: number) => (
                            <EventPollVotesRow
                                key={i}
                                item={item}
                            />
                        ))
                    }
                </tbody>
            </Table>
        </div >
    ) : <Loader />
    // return <Loader />
}

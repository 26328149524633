import { Dispatch, SetStateAction } from 'react';
import { INotificationProps } from '../types';

class HookHelper {
    init(data: {
        notificationUpdate: Dispatch<SetStateAction<INotificationProps>>
    }) {
        this.useNotification = data.notificationUpdate
    }

    useNotification (props: INotificationProps) {
        throw new Error('init required');   
    }
}

export const hookHelper: HookHelper = new HookHelper()

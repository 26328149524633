import { EApiRoutes, EWalletTransactionStatuses } from '../../configs/constants'
import { useEffect, useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { Button, Input, Table, Typography } from '@mui/material'
import { Loader } from '../../components/Loader'
import { Option, Select } from '@mui/joy'
import { AxiosRequestConfig } from 'axios'
import { prepareUrlWithId } from '../../helpers'
import { IUserTransaction } from '../../types'
import { NavigateFunction, useNavigate } from 'react-router'
import { TransfeConfirmPopper } from '../Poppers'

const TransactionsRow = (props: {
    item: IUserTransaction
    onTransferSubmit: (transferId: number, data: { paymentDetails: string }) => void
    onTransferCancelSubmit: (transferId: number) => void
    navigate: NavigateFunction
}) => {
    const { item } = props

    // const [updatedItem, updatedItemUpdate] = useState<Pick<IUserTransaction, 'id' | 'status'>>({
    //     id: item.id,
    //     status: item.status,
    // });

    return (
        <tr>
            <td>{item.id}</td>
            <td>{item.user.username}</td>
            <td>{item.createdAt}</td>
            <td>{item.updatedAt}</td>
            <td>{item.amount}</td>
            <td>{item.balanceBefore}</td>
            <td>{item.balanceAfter}</td>
            <td>{item.status}</td>
            <td>{item.transferType}</td>
            {/* <td>{JSON.stringify(item.details)}</td> */}
            <td>
                {
                    [EWalletTransactionStatuses.pending, EWalletTransactionStatuses.onReview].includes(item.status) &&
                    (<>
                        <TransfeConfirmPopper transferId={item.id} submitUpdate={props.onTransferSubmit} />
                        <Button onClick={() => props.onTransferCancelSubmit(item.id)} color='warning'>x</Button>
                    </>)
                }
                {/* <Button
                    onClick={() => onTransferSubmit(updatedItem)}
                ><Save color='success' /></Button> */}
            </td>
        </tr>
    )
}

export const Transactions = () => {
    const [itemsList, itemsListUpdate] = useState<IUserTransaction[]>(null as any)
    const [filter, filterUpdate] = useState<{ user: number, status: EWalletTransactionStatuses }>({
        // user: 0,
        status: EWalletTransactionStatuses.pending,
    } as any)

    const getItemsList = () => {
        const preparedParams: AxiosRequestConfig = {
            method: 'get',
            url: EApiRoutes.walletTransactions,
            params: { filter: JSON.stringify(filter) }
        }
        requestHelper
            .request(preparedParams)
            .then((items) => {
                itemsListUpdate(items)
            })
    }
    useEffect(getItemsList, [filter]);

    const navigate = useNavigate()
    const onTransferSubmit = (transferId: number, data: { paymentDetails: string }) => {
        requestHelper.request({
            method: 'post',
            url: prepareUrlWithId(EApiRoutes.adminTransferConfirm, transferId),
            data,
        }).then(() => {
            getItemsList()
        })
    }
    const onTransferCancelSubmit = (transferId: number) => {
        requestHelper.request({
            method: 'post',
            url: prepareUrlWithId(EApiRoutes.adminTransferCancel, transferId),
        }).then(() => {
            getItemsList()
        })
    }

    if (!itemsList) getItemsList()

    return itemsList ? (
        <div>
            <Typography variant='h6'>Транзакции</Typography>
            <div style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>
                <Input
                    placeholder='User id...'
                    value={filter.user}
                    onChange={(e): any => filterUpdate(e.target.value ? {
                        user: +e.target.value
                    } : {} as any)}
                />
                <Select
                    placeholder='Status'
                    value={filter.status}
                    onChange={(_, newValue): any => filterUpdate({
                        ...filter,
                        status: newValue as any
                    })}>
                    {
                        Object.values(EWalletTransactionStatuses).map((status: EWalletTransactionStatuses) => (
                            <Option value={status}>{status}</Option>
                        ))
                    }
                </Select>
            </div>
            <Table aria-label="basic table">
                <thead>
                    <tr>
                        <td>id</td>
                        <td>user</td>
                        <td>createdAt</td>
                        <td>updatedAt</td>
                        <td>amount</td>
                        <td>balanceBefore</td>
                        <td>balanceAfter</td>
                        <td>status</td>
                        <td>transferType</td>
                        {/* <td>details</td> */}
                        <td>Actions</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        itemsList.map((item: IUserTransaction, i: number) => (
                            <TransactionsRow
                                key={i}
                                item={item}
                                navigate={navigate}
                                onTransferSubmit={onTransferSubmit}
                                onTransferCancelSubmit={onTransferCancelSubmit}
                            />
                        ))
                    }
                </tbody>
            </Table>
        </div >
    ) : <Loader />
    // return <Loader />
}

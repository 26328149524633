import { EApiRoutes, EWalletTransactionStatuses, lang } from '../../configs/constants'
import { useEffect, useState } from 'react'
import { requestHelper } from '../../helpers/requestHelper'
import { Table, Typography } from '@mui/material'
import { Loader } from '../../components/Loader'
import texts from '../../configs/texts'
import { AxiosRequestConfig } from 'axios'
import { IUserInfo, IUserTransaction } from '../../types'
import { UserTransactionsDetailsModal } from './UserModals'

const UserTransactionsRow = (props: {
    item: IUserTransaction
}) => {
    const { item } = props

    return (
        <tr>
            <td>
                <UserTransactionsDetailsModal item={item} />
            </td>
            <td>{
                item.balanceAfter > item.balanceBefore
                    ? ''
                    : (item.balanceBefore > item.balanceAfter) ? '-' : ''
            }{item.amount}</td>
            <td>{item.balanceAfter}</td>
        </tr>
    )
}

export const UserTransactions = (props: { user: IUserInfo }) => {
    const [itemsList, itemsListUpdate] = useState<IUserTransaction[]>(null as any)
    const [filter] = useState<{ status: EWalletTransactionStatuses }>({
        // user: props.user.id
    } as any)

    const getItemsList = () => {
        const preparedParams: AxiosRequestConfig = {
            method: 'get',
            url: EApiRoutes.walletTransactions,
            params: { filter: JSON.stringify(filter) }
        }
        requestHelper
            .request(preparedParams)
            .then((items) => {
                itemsListUpdate(items)
            })
    }
    useEffect(getItemsList, [filter]);

    if (!itemsList) getItemsList()

    return itemsList ? (
        <div>
            <Typography variant='h6'>{texts[lang].transactions}</Typography>
            {/* <Input
                placeholder='Filter by username...'
                value={filter.username}
                onChange={(e): any => filterUpdate(e.target.value ? {
                    username: e.target.value
                } : {} as any)}
            /> */}
            <Table aria-label="basic table">
                <thead>
                    <tr>
                        <td>{texts[lang].id}</td>
                        <td>{texts[lang].amount}</td>
                        <td>{texts[lang].balanceAfter}</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        itemsList.map((item: IUserTransaction, i: number) => (
                            <UserTransactionsRow
                                key={i}
                                item={item}
                            />
                        ))
                    }
                </tbody>
            </Table>
        </div >
    ) : <Loader />
    // return <Loader />
}
